@tailwind base;
@tailwind components;
@tailwind utilities;

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

html, body {
    width: 100%;
    height: 100%;
}

.p-button {
    color: #3B82F6;
}

@layer components {
    .valben-button {
        @apply text-sm px-4 py-2 rounded-md transition-colors duration-150 bg-valamarblueprimary
            enabled:hover:bg-transparent text-valamargray enabled:hover:text-valamarblueprimary border-2
            border-valamarblueprimary enabled:cursor-pointer disabled:bg-valamargray disabled:border-valamargray
            disabled:text-valamarblueprimary/75
    }

    .field-title {
        @apply text-black text-base font-bold
    }

    .field-value {
        @apply text-black text-base
    }

    .expand-wrapper {
        @apply flex gap-2 items-center
    }

    .expand-gap {
        @apply grid gap-2
    }
}
